import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from "../../smart/field_group";
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Fetch from "../fetch";

export default class Modal extends SmartModal {
  renderDeleteForm = () => {
    return (
      <div>
        <div className="text-center">
          Are you sure? This will remove the customer from the Customers tab and Messages tab.
        </div>
      </div>
    )
  };

  renderForm() {
    return (
      <Fragment>
        <FieldGroup
          type="select"
          options={this.includedData('venues')}
          label="Venue"
          required={true}
          name="customer[venue_id]"
          defaultValue={this.formValue('venue_id')}
          error={this.formError('venue_id')}
        />
        <FieldGroup
          type="text"
          label="Name"
          required={true}
          name="customer[name]"
          defaultValue={this.formValue('name')}
          error={this.formError('name')}
        />
        <FieldGroup
          id="email"
          type="text"
          label="Email"
          required={true}
          name="customer[email]"
          defaultValue={this.formValue('email')}
          error={this.formError('email')}
        />
        <FieldGroup
          type="text"
          label="Phone number"
          name="customer[phone]"
          defaultValue={this.formValue('phone')}
          error={this.formError('phone')}
        />
        <FieldGroup
          type="text"
          label="Address"
          name="customer[address]"
          defaultValue={this.formValue('address')}
          error={this.formError('address')}
        />
        <FieldGroup
          type="text"
          label="Postcode"
          name="customer[postcode]"
          defaultValue={this.formValue('postcode')}
          error={this.formError('postcode')}
        />
      </Fragment>
    );
  }

  handleNewCustomerPerform = () => {
    const { path } = this.props;
    let data = this.getData();

    axios
      .post(`${path}.json`, data)
      .then((response) => {
        const venueId = data.get('customer[venue_id]');
        window.location = `/booking?venue_id=${venueId}&user_id=${response.data.data.user_id}`
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  renderNewButtons = () => {
    return (
      <div>
        <Button className='btn btn-default' onClick={this.handleClose}>Cancel</Button>
        <Button className='btn btn-primary' onClick={this.handlePerform}>Create</Button>
        <Button className='btn btn-warning' onClick={this.handleNewCustomerPerform}>Create and start new booking</Button>
      </div>
    )
  };

  showPaymentOptions = () => {
    this.showDefault('payment_options');
  };

  renderPaymentOptionsForm = () => {
    const venueCustomers = this.includedData('venue_customers');

    if (!venueCustomers) return null;

    return (
      <div>
        <h4 style={{ fontSize: 16 }}>
          Offline payments enabled for the following venue(s):
        </h4>

        <FieldGroup
          type="checkbox-group"
          name="user[venue_customers_attributes]"
          extendedName="offline_payment_enabled"
          options={venueCustomers.map((venueCustomer) => {
            return {
              id: venueCustomer.id,
              title: venueCustomer.venue.title,
              defaultChecked: venueCustomer.offline_payment_enabled
            }
          })}
        />
      </div>
    )
  };

  applyPaymentOptions = () => {
    this.applyDefault('payment_options');
  };

  showCreateBooking = () => {
    Fetch
      .venues()
      .then((response) => {
        this.setState({formData: null, included: { venues: { data: response.data.data } }, loading: false});
      });
  };

  renderCreateBookingForm = () => {
    const venues = this.includedData('venues');

    return (
      <div>
        <FieldGroup
          label="Venue"
          name="venue_id"
          type="radio-group"
          defaultValue={(venues[0] || {}).id}
          options={venues}
        />
      </div>
    );
  };

  applyCreateBooking = () => {
    const { data } = this.state;
    const userId = data.id;
    const venueId = this.getData().get('venue_id');

    window.location.href = `/booking/?venue_id=${venueId}&user_id=${userId}`
  };
}
