import React, { Component } from 'react';
import { ActionsCell as ParentActionsCell } from '../../smart/cells';
import PubSub from 'pubsub-js';
import { AbilityContext } from "../../ability_context";

export default class ActionsCell extends Component {
  handleCreateBooking(row) {
    PubSub.publish('Customer.CreateBooking', { id: row.user_id, modalAction: 'Create booking for ' })
  }

  handleViewBookings(row) {
    window.location.href = `/admin/bookings?terms=${row.name}`
  }

  handleSendMessage(row) {
    PubSub.publish(
      'Message.New',
      {
        inbox: { type: 'Venue', id: row.venue_id },
        interlocutor: { type: 'User', id: row.user_id } //, name: row.name }
      }
    )
  }

  handlePaymentOptions(row) {
    PubSub.publish('Customer.PaymentOptions', { id: row.id })
  }

  handleDelete(row) {
    PubSub.publish('Customer.Delete', { id: row.id });
  }

  handleAttachments(row) {
    PubSub.publish('ListAttachments.Index',
      {
        venue_customer_id: row.id,
        modalTitle: `${row.name} - Document(s) provided`
      }
    );
  }

  render() {
    const { row, bsSize } = this.props;

    return (
      <AbilityContext.Consumer>
        {(ability) =>
          <ParentActionsCell
            row={row}
            bsSize={bsSize}
            items={[
              { title: 'Create booking', action: this.handleCreateBooking },
              { title: 'View bookings', action: this.handleViewBookings },
              { title: 'Messages', action: this.handleSendMessage },
              { title: 'Payment options', action: this.handlePaymentOptions },
              { title: 'Delete', action: this.handleDelete, hidden: !ability.can('delete', 'AdminCustomers') },
              { title: 'Document manager', action: this.handleAttachments },
            ]}
          />
        }
      </AbilityContext.Consumer>
    )
  }
}
